<script>
import ProjectList from "components/suporte/components/projetos/List"
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item"
import {UPopover} from "uloc-vue"
export default {
  name: 'SuporteClientes',
  mixins: [],
  components: {
    ProjectList,
    MenuOptionsItem,
    UPopover
  },
  data () {
    return {
      listOptions: {
        loading: false,
        serverPagination: {
          page: 1,
          rowsNumber: 0,
          rowsPerPage: 20
        }
      }
    }
  },
  computed: {
  },
  methods: {
  },
  meta: {
    title: 'Clientes',
    name: 'Clientes'
  }
}
</script>

<template>
  <div class="scontent">
    <ul class="snav">
      <li><a @click="$router.push({name: 'suporte.dashboard'})">Suporte</a></li>
      <li class="disable">Clientes</li>
    </ul>
    <div class="stitle">
      <h2>Clientes</h2>
      <div class="sopts">
        <u-btn label="Criar" no-caps color="blue-8"/>
        <u-btn flat size="sm" class="m-l-xs">
          <i class="fa fa-ellipsis-h font-14" />
          <u-popover ref="popover" class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 15]" style="min-width: 172px">
            <div>
              <ul>
                <menu-options-item label="Configurações" close  />
              </ul>
            </div>
          </u-popover>
        </u-btn>
      </div>
    </div>
    <project-list :data="[]" :options="listOptions" />
  </div>
</template>
